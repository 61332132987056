/* Vibrant Gradient Background */
.vibrant-gradient {
  background: linear-gradient(135deg, #f857a6, #ff5858, #ff9a8b);
  background-size: cover;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}

/* Subtle Pattern Overlay */
.vibrant-gradient::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("https://www.transparenttextures.com/patterns/arches.png");
  opacity: 0.12;
  mix-blend-mode: overlay;
  pointer-events: none;
  z-index: 1;
}

/* Faint Grid for Extra Texture */
.vibrant-gradient::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0.1) 75%,
    transparent 75%,
    transparent
  );
  background-size: 30px 30px;
  opacity: 0.1;
  pointer-events: none;
  z-index: 2;
}
/* Frosted Gray Circle */
.gray-circle {
  background: rgba(240, 243, 248, 0.85);
  backdrop-filter: blur(10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  z-index: 3;
}
